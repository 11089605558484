<template>
  <section style="margin: 0 24px">
    <nav v-if="!$route.params.id" class="tabs-navbar" :class="{ dark: false }">
      <ul class="tabs-navbar-list">
        <!-- v-can="item.permission" -->
        <div v-for="(item, idx) in pageList" :key="idx">
          <li
            v-if="$route.query.slug === item.slug"
            class="tabs-navbar-list__item"
          >
            <router-link
              class="tabs-navbar-list__item-link"
              :to="{
                path: `/settings-category/${item.link}`,
                query: { slug: $route.query.slug },
              }"
            >
              {{ $t(item.name) }}
            </router-link>
          </li>
        </div>
      </ul>
    </nav>

    <router-view />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeName: "",
      pageList: [
        {
          name: "message.services",
          link: "services/index",
          permission: "services.index",
          slug: "system-settings",
        },
        {
          name: "message.countries",
          link: "states/index",
          permission: "states.index",
          slug: "system-settings",
        },
        {
          name: "message.region",
          link: "region/index",
          permission: "regions.index",
          slug: "system-settings",
        },
        {
          name: "message.cities",
          link: "cities/index",
          permission: "cities.index",
          slug: "system-settings",
        },
        {
          name: "message.condition",
          link: "conditions/index",
          permission: "conditions.index",
          slug: "system-settings",
        },
        {
          name: "message.owner_clinic",
          link: "ownerClinic/index",
          permission: "ownerClinic.index",
          slug: "system-settings",
        },
        {
          name: "message.order_column",
          link: "orderColumn/index",
          permission: "roles.index",
          slug: "system-settings",
        },
        {
          name: "message.excelReport",
          link: "excelColumns/index",
          permission: "excelReports.3",
          slug: "system-settings",
        },
        {
          name: "message.category_services",
          link: "categoryServices/index",
          permission: "categoryServices.index",
          slug: "system-settings",
        },
        {
          name: "message.doctorSign",
          link: "doctorSign/index",
          permission: "doctorSign.index",
          slug: "med-settings",
        },
        {
          name: "message.specialities",
          link: "specialties/index",
          permission: "specialties.index",
          slug: "med-settings",
        },
        {
          name: "message.rooms",
          link: "rooms/index",
          permission: "rooms.index",
          slug: "med-settings",
        },
        {
          name: "message.time",
          link: "hours/index",
          permission: "hours.index",
          slug: "med-settings",
        },
        {
          name: "message.work_schedule",
          link: "workTimes/index",
          permission: "work_times.index",
          slug: "med-settings",
        },
        {
          name: "message.partner_doctor",
          link: "partnerDoctor/index",
          permission: "partner_doctors.index",
          slug: "partners",
        },
        {
          name: "message.partner_clinic",
          link: "partnerClinic/index",
          permission: "partner_doctors.index",
          slug: "partners",
        },
        {
          name: "message.currency",
          link: "currencies/index",
          permission: "currencies.index",
          slug: "cassa",
        },
        {
          name: "message.payment_type",
          link: "paymentTypes/index",
          permission: "payment_types.index",
          slug: "cassa",
        },
        {
          name: "message.flow_types",
          link: "costs/index",
          permission: "costs.index",
          slug: "cassa",
        },
        {
          name: "message.currency",
          link: "currenciesWarehouses/index",
          permission: "currencies.index",
          slug: "warehouse",
        },
        {
          name: "message.groups",
          link: "groupsWarehouses/index",
          permission: "currencies.index",
          slug: "warehouse",
        },
        {
          name: "message.measurements",
          link: "measurementsWarehouses/index",
          permission: "currencies.index",
          slug: "warehouse",
        },
        {
          name: "message.status",
          link: "statuses/index",
          permission: "currencies.index",
          slug: "warehouse",
        },
        {
          name: "message.category_mkb",
          link: "categoryFormTemplates/index",
          permission: "categoryFormTemplates.index",
          slug: "conclusion",
        },
        {
          name: "message.forms_of_documents",
          link: "documentations/index",
          permission: "documentations.index",
          slug: "conclusion",
        },
        {
          link: "users/index",
          name: "message.users",
          permission: "users.index",
          slug: "actions",
        },
        {
          link: "roles/index",
          name: "message.roles",
          permission: "roles.index",
          slug: "actions",
        },
        {
          link: "permissions/index",
          name: "message.access_rights",
          permission: "permissions.index",
          slug: "actions",
        },
        {
          link: "printAudit",
          name: "message.print_audit",
          permission: "print_audit.index",
          slug: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      role: "role",
      // mode: "MODE"
    }),
    // currentTabs() {
    //     if (this.$route.params.slug === "directory") {
    //         return this.directoryTabs;
    //     } else if (this.$route.params.slug === "finance") {
    //         return this.financeTabs;
    //     } else if (this.$route.params.slug === "production") {
    //         return this.productionTabs;
    //     }
    // }
  },

  mounted() {
    this.activeName = this.$route.name;
  },
  methods: {
    handleClickTabs(tab) {
      this.activeName = tab.name;
    },
  },
};
</script>

<style lang="scss"></style>
